<template>
  <form
    @submit.prevent="$router.push('/otp')"
    class="auth-form-container columnd"
  >
    <h1 class="text-start f28-700 c-darkBlue">Forgot password</h1>
    <div class="inputs columnd">
      <h6 class="text-start f14-400 c-darkGrey">
        Please enter your Email Address to get in
      </h6>
      <div class="input-container">
        <span> Email Adrress </span>
        <label for="">
          <input placeholder="Email" type="text" />
        </label>
      </div>
      <button @click.prevent="$router.push('/otpform')" class="primary-btn">
        Send Code
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: "Login",
};
</script>

<style lang="scss" scoped>
.input-check {
  gap: 3px;
  input {
    border: 1px solid var(--darkBlue);
    &:checked {
      background: none !important;
    }
  }
}
</style>
