<template>
  <form
    @submit.prevent="$router.push('/otp')"
    class="auth-form-container columnd"
  >
    <h1 class="text-start f28-700 c-darkBlue">Verificaton link sent!</h1>
    <div class="inputs columnd">
      <h6 class="text-start f14-400 c-darkGrey">
        We Emailed a confirmation code to marjan.kh96@gmail.com check your Email
        and write code here..
      </h6>
      <CodeInput />
    </div>
    <span class="f14-700 c-darkGrey"> 12:26 </span>
    <button class="primary-btn">Confirm</button>
  </form>
</template>

<script>
import CodeInput from "../Tools/CodeInput.vue";
export default {
  name: "Otp",
  components: {
    CodeInput,
  },
  data() {
    return {
      otp: {
        code: "",
      },
    };
  },
  methods: {
    onChange(v) {
      this.otp.code = v;
    },
    handleSubmit() {
      if (this.$route.name == "OtpLogin") {
        this.submitAuth(this.otp.code);
      } else if (this.$route.name == "OtpForget") {
        this.submitForget(this.otp.code);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-check {
  gap: 3px;
  input {
    border: 1px solid var(--darkBlue);
    &:checked {
      background: none !important;
    }
  }
}
</style>
