<template>
  <div class="rowd space-between auth-container align-center">
    <Register v-if="$route.name === 'Register'" />
    <Login v-if="$route.name === 'Login'" />
    <Otp v-if="$route.path.includes('otp')" />
    <ForgetPass v-if="$route.name === 'ForgetPass'" />
    <NewPass v-if="$route.name === 'NewPass'" />
  </div>
</template>

<script>
import Register from "./Register.vue";
import Login from "./Login.vue";
import Otp from "./Otp.vue";
import ForgetPass from "./ForgetPass.vue";
import NewPass from "./NewPass.vue";
export default {
  components: {
    Register,
    Login,
    Otp,
    ForgetPass,
    NewPass,
  },
  name: "Forms",
};
</script>

<style lang="scss" scoped></style>
