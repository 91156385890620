<template>
  <form
    @submit.prevent="$router.push('/otpform')"
    class="auth-form-container columnd"
  >
    <h1 class="text-start f28-700 c-darkBlue">Login</h1>
    <div class="inputs columnd">
      <h6 class="text-start f14-400 c-darkGrey">
        Please enter your Email Address to get in
      </h6>
      <div class="input-container">
        <span> Email Adrress </span>
        <label for="">
          <input placeholder="Email" type="text" />
        </label>
      </div>
      <div class="input-container">
        <span> Your password </span>
        <label for="">
          <input placeholder="Password" type="text" />
        </label>
      </div>
      <a
        @click.prevent="$router.push('/forgetpass')"
        class="f12-400 link text-start"
        href=""
      >
        Forget Password ?
      </a>
      <button class="primary-btn">Login</button>
      <button class="secondary-btn align-center justify-center">
        <img src="../../assets/Icons/Google.svg" alt="" />
        Sign Up With Google
      </button>
      <div class="input-check rowd align-center">
        <span class="f12-400 c-ddGrey">Already on Radin?</span>
        <a
          @click.prevent="$router.push('/register')"
          class="f12-700 c-darkOrange"
          href=""
        >
          SignUp
        </a>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "Login",
};
</script>

<style lang="scss" scoped>
.input-check {
  gap: 3px;
  input {
    border: 1px solid var(--darkBlue);
    &:checked {
      background: none !important;
    }
  }
}
</style>
