<template>
  <form
    @submit.prevent="$router.push('/otpform')"
    class="auth-form-container columnd"
  >
    <h1 class="text-start f28-700 c-darkBlue">Register</h1>
    <div class="inputs columnd">
      <h6 class="text-start f14-400 c-darkGrey">
        Please enter your Email Address to get in
      </h6>
      <div class="input-container">
        <span> Email Adrress </span>
        <label for="">
          <input placeholder="Email" type="text" />
        </label>
      </div>
      <div class="input-container">
        <span> Your password </span>
        <label for="">
          <input placeholder="Password" type="text" />
        </label>
      </div>
      <div class="input-container">
        <span> Referral code </span>
        <label for="">
          <input placeholder="Referral Code (Optional)" type="text" />
        </label>
      </div>
      <div class="input-check rowd align-center">
        <input type="checkbox" name="" id="" />
        <span class="f12-400 c-ddGrey">By continuing you agree to Radin’s</span>
        <a class="f12-400 link" href=""> Terms of Service </a>
      </div>
      <button class="primary-btn">Register</button>
      <button class="secondary-btn align-center justify-center">
        <img src="../../assets/Icons/Google.svg" alt="" />
        Sign Up With Google
      </button>
      <div class="input-check rowd align-center">
        <span class="f12-400 c-ddGrey">Already on Radin?</span>
        <a
          @click.prevent="$router.push('/login')"
          class="f12-700 c-darkOrange"
          href=""
        >
          LogIn
        </a>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "Register",
};
</script>

<style lang="scss" scoped>
.input-check {
  gap: 3px;
  input {
    border: 1px solid var(--darkBlue);
    &:checked {
      background: none !important;
    }
  }
}
</style>
